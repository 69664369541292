<template>
  <div class="loadingInfo flex-column flex-center align-center">
    <!-- <h3>{{ selectedInfo.title }}:</h3>
    <h2>{{ selectedInfo.info }}</h2> -->
  </div>
  <!-- <p class="mute noselect">Bilgi al (<i class="noevent las la-book"></i>) -> Deprem Anı</p> -->
</template>

<script>
export default {
  props: {
    selectedInfo: {},
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .loadingInfo {
    * {
      align-self: center;
      text-align: center;
    }
    h3 {
      font-size: small;
    }
    h2 {
      font-size: 18px;
    }
  }
}
.loadingInfo {
  text-align: center;
  width: 90%;
  h3 {
    margin: 5px 10px;
    border-radius: 10px;
    padding: 5px 10px;
  }
  h2 {
    margin: 0 auto;
    padding: 10px 1rem;
    border-radius: 10px;
  }

  h2,
  h3 {
    // background-color: rgba($color: #e7f7fa, $alpha: .7);
    // // background: linear-gradient(to right, transparent 0%, rgba($color: #e7f7fa, $alpha: .7) 30%, rgba($color: #e7f7fa, $alpha: .7) 70%, transparent 100%);

    background-size: 200% 200%;
    // animation: glossy 2s ease-in infinite;

    overflow: hidden;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 95%;
      // border-image: linear-gradient(to right, transparent 0%, rgba($color: black, $alpha: .2) 30%, rgba($color: white, $alpha: .6) 50%, transparent 100%) 100;
      // border-width: 1px;
      // border-style: solid;
      // animation: slideBorder 2s linear infinite;
    }
  }
}

@keyframes glossy {
  0% {
    background-position: 200%;
  }
  100% {
    background-position: 0%;
  }
}

@keyframes slideBorder {
  30% {
    left: -100%;
  }
  70% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

p {
  margin: 1rem;
  font-size: small;
}
</style>
