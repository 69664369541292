<template>
  <div :key="instance" class="QuakesMap">
    <Suspense>
      <template #default>
        <QuakesMap style="height: 100%; position: fixed" />
      </template>
      <template #fallback>
        <div class="flex-column noevent" style="height: 100vh">
          <div class="gradient-container"></div>
          <img id="loadingImage" :src="logo" />
          <span id="byEA">by Evrim Ağacı</span>
          <LoadingInfo :selectedInfo="quakeInfo" />
          <div class="loadingStyle flex-center flex-column align-center">
            <h4 id="loadingText" style="color: black">Yükleniyor...</h4>
          </div>
        </div>
      </template>
    </Suspense>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const QuakesMap = defineAsyncComponent({
  loader: () => import("@/components/displays/QuakesMap.vue"),
});
// import QuakesMap from '@/components/displays/QuakesMap.vue'

import logo from "@/assets/logo.svg";
import LoadingInfo from "@/components/addons/LoadingInfo.vue";
// import NavMenu from '@/components/addons/NavMenu.vue'

import quakeInfo from "@/assets/data/quakeInfo.json";

export default {
  name: "App",
  components: {
    LoadingInfo,
    // eslint-disable-next-line vue/no-unused-components
    QuakesMap,
    // NavMenu,
  },
  data() {
    return {
      instance: 0,
      logo: logo,
      loading: true,
      quakeInfo: {},
    };
  },
  methods: {
    reloadData() {
      location.reload();
    },
  },
  mounted() {
    let quakeInfoCategory =
      quakeInfo[Math.floor(Math.random() * quakeInfo.length)];
    this.quakeInfo = {
      title: quakeInfoCategory.title,
      info: quakeInfoCategory.body[
        Math.floor(Math.random() * quakeInfoCategory.body.length)
      ],
    };
  },
};
</script>

<style lang="scss">
// * {outline: 2px solid rgb(255, 60, 60) !important;}
* {
  box-sizing: border-box;
}

.gradient-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  animation: gradientAnimation 5s linear forwards;
}

@keyframes gradientAnimation {
  50% {
    background: linear-gradient(to bottom, #efefef, #d0cfd3);
  }
  100% {
    background: linear-gradient(to bottom, #d0cfd3, #efefef);
  }
}

#byEA {
  position: absolute;
  left: calc(50% + 33px);
  top: 110px;
  font-size: 16px;
  color: black;
}

#testHeader {
  display: none;

  @media only screen and (orientation: landscape) {
    display: block;
  }
}

#loadingText {
  opacity: 0;
  animation: pulse 5s ease-in-out infinite;
  z-index: 99999;
}

@keyframes pulse {
  0% {
    opacity: 1;
    font-size: x-large;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --slider-bg: #b5bee2;

  --slider-connect-bg: #3b82f6;
  --slider-handle-ring-color: #3b82f630;

  --slider-handle-height: 20px;
  --slider-handle-width: 20px;
  --slider-height: 20px;
  @media only screen and (min-width: 600px) {
    --slider-handle-height: 10px;
    --slider-handle-width: 10px;
    --slider-height: 10px;
  }
  @media only screen and (min-width: 2048px) {
    --slider-handle-height: 20px;
    --slider-handle-width: 20px;
    --slider-height: 20px;
  }
  @media only screen and (min-width: 3700px) {
    --slider-handle-height: 25px;
    --slider-handle-width: 25px;
    --slider-height: 25px;
  }

  --slider-vertical-height: 600px;
  --slider-tooltip-bg: #fff;
  --slider-tooltip-color: black;
  --slider-tooltip-distance: -5px;

  --color-one: #52117d;
  --color-two: #a944ec;
  --color-three: white;
  --color-accent: black;
  --color-text: #fffed7;
  --color-bg: transparent;
}
// main color #b5bee2
// back color rgb(97, 85, 141)

@font-face {
  font-family: "NunitoSans";
  src: url("./assets/NunitoSans-Regular.ttf");
}

body {
  margin: 0;
  @media only screen and (max-width: 600px) {
    align-items: flex-start;
  }

  font-family: "NunitoSans", sans-serif;
}

html {
  // background: #d5e8eb;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.twitter {
  position: absolute;
  background-color: white;
  display: flex;
  left: 0px;
  bottom: 2rem;
  border-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;

  font-size: clamp(1rem, 1.3vw, 5rem);

  z-index: 2;

  transition: all 220ms linear;
  &:hover {
    cursor: pointer;
    box-shadow: (0 0 2px black);
  }

  p {
    font-weight: bold;
    margin: 0;
  }

  #twitterLogo {
    width: clamp(1rem, 1.5vw, 5rem);
    // margin-right: 10px;
  }
}
.menu-wrapper,
.slide-out {
  box-shadow: 0 !important;
}
.menu-container {
  background-color: rgba($color: #fff, $alpha: 0.9) !important;
  border-radius: 10px;
  min-height: 2rem !important;

  .close-btn {
    display: none;
  }

  .menu-list-item {
    border-radius: 10px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 2px;

    &:hover {
      background-color: rgba($color: #fff, $alpha: 1) !important;
      color: black !important;
    }
  }
}

.loadingStyle {
  position: absolute;
  overflow: hidden;
  font-size: x-large;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 0;
}

#loadingImage {
  margin: 2rem auto;
  width: 100%;
  transform: translateX(-4px);
  height: 120px;
  z-index: 2 !important;
}

#enterSite {
  font-size: large;
  border: 1px solid black;
  border-radius: clamp(1rem, 1vw, 3rem);
  padding: 1rem 2rem;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #3b82f6;
  }
}

.popupItem {
  padding: 0 5px;
  padding-top: 5px;
  border-bottom: 1px solid black;
}

/* set the style for the clusters */
.marker-cluster {
  background-color: white;
  border-radius: clamp(1rem, 1vw, 3rem);
  color: black;
  border: 1px solid black;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  line-height: 40px;
  cursor: pointer;
}

/* set the style for the clusters when hovered */
.marker-cluster:hover {
  font-weight: bold;
}

.leaflet-pulsing-icon {
  border-radius: 100%;
  box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.75);
}

.leaflet-pulsing-icon:after {
  content: "";
  border-radius: 100%;
  height: 300%;
  width: 300%;
  position: absolute;
  margin: -100% 0 0 -100%;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    pointer-events: all;
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    pointer-events: none;
  }
  100% {
    transform: scale(2, 2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    pointer-events: none;
  }
}

// .newItem {
//   position: absolute;
//   font-size: 1vmax !important;
//   color: #3b82f6;
//   left: 0;
//   top: 0;
//   padding: 4px;
//   animation: appear 1s linear;
//   transform-origin: center;
//   transform: scale(1);
// }

// @keyframes appear {
//   from {
//     opacity: 0;
//     transform: scale(4) rotate(0deg);
//   }
//   to {
//     opacity: 1;
//     transform: scale(1) rotate(360deg);
//   }
// }

.newItem {
  // background-color: #3b82f6;
  color: white;
  animation: newitem 3s linear infinite;
  transform-origin: center;
}

@keyframes newitem {
  0% {
    color: initial;
  }
  25% {
    color: #3b82f6;
  }
  50% {
    color: initial;
  }
  100% {
    color: initial;
  }
}

.newNavItem {
  // background-color: #3b82f6;
  color: white !important;
  animation: newnavitem 3s linear infinite !important;
  transform-origin: center;
}

@keyframes newnavitem {
  0% {
    color: white !important;
  }
  25% {
    color: #3b82f6;
  }
  50% {
    color: white !important;
  }
  100% {
    color: white !important;
  }
}

a {
  text-decoration: none;
  color: unset;
  &:hover {
    cursor: pointer;
  }
}

input {
  background: transparent;
  border: 0;
  margin: 0;
  height: 2rem;
  margin-right: 10px;
  font-size: normal;

  display: inline-block;

  @media only screen and (min-width: 2048px) {
    height: 3rem;
    font-size: large;
  }
  @media only screen and (max-width: 600px) {
    font-size: normal;
  }

  @media only screen and (min-width: 2048px) {
    font-size: large;
  }

  @media only screen and (min-width: 3700px) {
    font-size: x-large;
  }
}

.checkbox {
  display: inline-flex;
  color: var(--color-text);
  font-weight: 600;
  align-items: center;
  line-height: 1;
  border-radius: clamp(1rem, 1vw, 3rem);
  color: #010101;
  user-select: none;

  @media (min-width: 992px) {
    font-size: 1.25rem;
  }

  span {
    position: relative;
    display: flex;
    align-items: center;
  }

  input[type="checkbox"] {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 1px solid black;
    height: 1px;
    width: 1px;
    overflow: hidden;

    &:checked + span::after {
      background-color: var(--color-accent);
    }

    &:checked + span {
      color: var(--color-accent);
    }

    &:focus + span::before {
      border-color: var(--color-accent);
    }

    &:disabled {
      opacity: 0.85;
      & + span {
        color: #9a9a88;
      }
    }
  }

  span::before {
    content: "";
    display: inline-block;
    border-radius: 3px;
    background-color: transparent;
    margin-right: 8px;
    height: 16px;
    width: 16px;
    border: 1px solid transparent;
  }

  span::after {
    content: "";
    display: inline-block;
    height: 12px;
    width: 12px;
    border-radius: 3px;
    background-color: transparent;
    left: 3px;
    position: absolute;
  }
}

button {
  border: 1px solid rgba($color: #b2cee6, $alpha: 0.4);
  padding: clamp(0.4rem, 0.4vw, 0.5rem);
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  border-radius: clamp(0.5rem, 0.5vw, 1.5rem);
  text-decoration: none;

  &:hover {
    filter: brightness(0.97);
    cursor: pointer;
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

h1 {
  text-align: center;
  font-size: 2rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

h2,
h3 {
  margin: 10px;
}

#dateAndMode {
  color: black;
  position: absolute;
  top: 11px;
  z-index: 2;
  // background: -webkit-linear-gradient(120deg, #b5bee2, #b3bef0);
  // background: linear-gradient(120deg, #b5bee2, #b3bef0);

  background-color: white;
  border-radius: clamp(1rem, 1vw, 3rem);
  padding: 8.24px;
  border: 1px solid black;
  span {
    margin-bottom: 5px;
  }
}

.mirror {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.glass {
  background-color: rgba(222, 227, 232, 0.8) !important;
  backdrop-filter: blur(2px) !important;
}

.mute {
  opacity: 0.5;
}
.highlight {
  position: relative;
  border: 1px dotted rgba(0, 0, 0, 1) !important;

  &::before {
    content: "🔍";
    position: absolute;
    bottom: 5px;
    right: 20%;
    font-size: large;
    transform: translateX(-50%);
    width: auto;
    padding: 2px 5px;
    border-radius: 0.5rem;
    text-align: center;
    color: transparent;
    animation: blink 1s infinite;
  }
}

@keyframes blink {
  50% {
    color: black;
  }
}

.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
.flex-evenly {
  display: flex;
  justify-content: space-evenly;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.flex-v {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.fade {
  visibility: visible;
  opacity: 1;
  transition: opacity 50ms linear;
}
.inactive {
  visibility: hidden !important;
  opacity: 0 !important;
  height: 0 !important;
  width: 0 !important;
  // transition: visibility 0s 50ms, opacity 50ms linear;
}
.sizedinactive {
  visibility: hidden !important;
  opacity: 0 !important;
}
.divider {
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
  margin-bottom: 5px;
}
.active {
  display: block !important;
}
.invisible {
  display: none !important;
}

.nomargin {
  * {
    margin: 0;
  }
}

.nomarginY {
  * {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.nomarginX {
  * {
    margin-left: 0;
    margin-right: 0;
  }
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.noevent {
  pointer-events: none;
}
svg * {
  transform-box: fill-box;
}
// path:hover {
//   cursor: crosshair;
// }

@keyframes chosenQuakeAnimation {
  0% {
    stroke: #000;
    stroke-width: 0.1%;
  }
  40% {
    stroke-opacity: 1;
  }
  100% {
    stroke: rgba(0, 0, 0, 0);
    stroke-width: 1%;
    stroke-opacity: 0;
  }
}

@keyframes recentQuakeAnimation {
  0% {
    stroke: #000;
    stroke-width: 0.1%;
  }
  40% {
    stroke: #000;
    stroke-opacity: 1;
  }
  100% {
    stroke: rgba(0, 0, 0, 0);
    stroke-width: 4%;
    stroke-opacity: 0;
  }
}

.chosenQuake {
  -webkit-animation: chosenQuakeAnimation 3s ease infinite !important;
  -moz-animation: chosenQuakeAnimation 3s ease infinite !important;
  -ms-animation: chosenQuakeAnimation 3s ease infinite !important;
  -o-animation: chosenQuakeAnimation 3s ease infinite !important;
  animation: chosenQuakeAnimation 3s ease infinite !important;
  transform-origin: center !important;
}

.recentQuake {
  -webkit-animation: recentQuakeAnimation 3s ease infinite !important;
  -moz-animation: recentQuakeAnimation 3s ease infinite !important;
  -ms-animation: recentQuakeAnimation 3s ease infinite !important;
  -o-animation: recentQuakeAnimation 3s ease infinite !important;
  animation: recentQuakeAnimation 3s ease infinite !important;
  transform-origin: center !important;
}

.lastQuake {
  -webkit-animation: recentQuakeAnimation 2s ease-in infinite !important;
  -moz-animation: recentQuakeAnimation 2s ease-in infinite !important;
  -ms-animation: recentQuakeAnimation 2s ease-in infinite !important;
  -o-animation: recentQuakeAnimation 2s ease-in infinite !important;
  animation: recentQuakeAnimation 2s ease-in infinite !important;
  stroke: rgba($color: #f00, $alpha: 0.4) !important;
  transform-origin: center;
}

.blur {
  -webkit-backdrop-filter: blur(20rem);
  -moz-backdrop-filter: blur(20rem);
  -o-backdrop-filter: blur(20rem);
  -ms-backdrop-filter: blur(20rem);
  backdrop-filter: blur(20rem);
}

.mapMarker {
  position: relative;
  z-index: 2;
  &::before {
    content: "3.5";
    font-size: 5rem;
    color: white;
    display: block;
  }
  filter: drop-shadow(0 0 0 rgb(0 0 0 / 0));
}
#quaketip {
  background-color: red;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 2;
}

/* Rounded sliders */
.slider.round {
  border-radius: 0;
  &:hover {
    background: #000;
  }
}

.slider.round:before {
  border-radius: 0%;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  appearance: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
  border: 1px solid rgba($color: #000, $alpha: 0.5);
  border-radius: 0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(97, 85, 141);
}

@media (hover: none) {
  .leaflet-tooltip-pane {
    display: none;
  }
}
</style>
